<template>
  <div>
    <v-toolbar v-if="!isEmbeddedInApp" dark extended extension-height="24" class="profile--header">
      <v-avatar v-if="result && result.img" size="110" class="profile--img"><img :src="result.img+(result.img.indexOf('?')>0?'&':'?')+'w=250&h=250'" :title="`Profile Picture of ${result.name}`"/></v-avatar>
      <v-avatar v-if="result && !result.img && hasExternalPhotos" max-height="110" size="110" class="profile--img" @click="$refs.photos.scrollIntoView()"><v-img :src="photos[0].thumb"  :title="`Picture of ${result.name}`"/></v-avatar>
      <v-toolbar-title class="pl-4">
        {{ result ? result.name : '...' }}
      </v-toolbar-title>
      <v-spacer/>
      <v-btn v-if="popupMode" text color="white" @click="$emit('closeClick')"><v-icon class="mr-2">fa-times-circle</v-icon> {{$t('shared.close')}}</v-btn>
      <!-- <ShareButton v-if="result && event && race && !isEmbeddedInApp" :text="`Results at the ${event.name} - Check sodisp.com`" color="white" :route="{name: 'raceResult', params: { id: event.id, raceId: race.id, resultId: result.id}}" /> -->
    </v-toolbar>    
    <div v-if="!isEmbeddedInApp && result && result.id" style="margin-top:-20px" :class="'pl-4 profile--actions ' + (result.img || hasExternalPhotos ? 'with-img' : '')">
      <v-btn v-if="!liked && allowLike" outlined rounded :color="useHighContrast ? 'black' : event.color || 'primary'" class="mr-2 mb-2" @click="like" :loading="$store.getters.isLoading" style="background:white;">
        <v-icon class="mr-2">fa-thumbs-up</v-icon>{{$t('results.details.like')}}{{ result.likes ? ` (${result.likes})` :'' }}
      </v-btn>
      <v-btn v-if="liked && allowLike" outlined rounded :color="useHighContrast ? 'black' : event.color || 'primary'" class="mr-2 mb-2" disabled style="background:white;">
        <v-icon class="mr-2">fa-thumbs-up</v-icon>{{$t('results.details.liked')}}
      </v-btn>
      <v-btn v-if="!isEmbeddedInTeams && allowComments" outlined rounded class="mr-2 mb-2" :color="useHighContrast ? 'black' : event.color || 'primary'" style="background:white;" @click="$refs.comments.scrollIntoView()">
        <v-icon class="mr-2">fa-comments</v-icon>{{$t('results.details.comment')}}{{ result.comments ? ` (${result.comments})` :'' }}
      </v-btn>
      <v-btn v-if="result.photos" outlined rounded class="mr-2 mb-2" :color="useHighContrast ? 'black' : event.color || 'primary'" style="background:white;" @click="$refs.photos.scrollIntoView()">
        <v-icon class="mr-2">fa-images</v-icon> <span v-if="result.photos > 0">{{ result.photos }}</span> {{$t('results.details.photos')}}
      </v-btn>
      <v-btn v-if="!result.photos && result.photos_url" outlined rounded class="mr-2 mb-2" :color="useHighContrast ? 'black' : event.color || 'primary'" style="background:white;" :href="result.photos_url" target="_blank">
        <v-icon class="mr-2">fa-images</v-icon> 
        {{$t('results.details.photos')}} 
        <v-icon x-small class="ml-1">fa-external-link</v-icon>
      </v-btn>
      <v-btn v-if="allowFriend" outlined rounded class="mr-2 mb-2" :color="event.color || 'primary'" style="background:white;" @click="requestFriend">
        <v-icon class="mr-2">fa-user-plus</v-icon> {{$t('results.details.add-friend')}}
      </v-btn>
      <v-btn v-if="result.can_claim" outlined rounded class="mr-2 mb-2" :color="event.color || 'primary'" style="background:white;" @click="claimResult">
        <v-icon class="mr-2">fa-user</v-icon> {{$t('results.details.claim')}}
      </v-btn>
      <ShareButton v-if="result && event && race && !isEmbeddedInApp && allowShare" outlined rounded buttonClass="mr-2 mb-2" buttonStyle="background:white;" :text="`Results at the ${event.name} - Check sodisp.com`" :color="useHighContrast ? 'black' : event.color || 'primary'" :route="{name: 'raceResult', params: { id: event.id, raceId: race.id, resultId: result.id}}" />
    </div>

    <v-alert v-if="result && result.can_claim && claimVisible" type="info" outlined prominent class="ma-4">
      <p>Is this your result? You can claim this to add it to your profile and to upload photos and selfies to it.</p>
      <div v-if="user">
        <v-btn color="info" :to="{name: 'raceClaimResult', params: { id:event.id, raceId: race.id, resultId: result.id }}">Claim now</v-btn>        
      </div>
      <div v-else>
        <p>Before you can claim your result you'll need to log in or create an account.</p>
        <v-btn color="info" class="mr-4" :to="{ name: 'register', query: { redirect:this.$router.resolve({name: 'raceClaimResult', params: { id:event.id, raceId: race.id, resultId: result.id }}).href }}">{{$t('nav.account.register')}}</v-btn>
        <v-btn outlined color="info" :to="{name: 'login', query: { redirect:this.$router.resolve({name: 'raceClaimResult', params: { id:event.id, raceId: race.id, resultId: result.id }}).href } }">{{$t('nav.account.login')}}</v-btn>
      </div>
    </v-alert>

    <v-card-text v-if="!result" class="mt-4 pb-0 px-2" style="text-align:center;">
      <v-progress-circular indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
    </v-card-text>

    <v-card-text v-if="result" class="mt-4 pb-0 px-2">
      <span v-if="!highlightPositions && result.pos" class="ms-2 font-weight-bold">{{$t('results.cols.position')}} #{{ result.pos }}</span>
      <span v-if="!highlightPositions && result.gender && !race.team && hasColumn('GENDER')" class="ms-2">
          • <span class="grey--text">{{$t('results.cols.gender')}}</span> {{ result.gender }}:
          <span v-if="result.gender_pos" class="ml-0">#{{ result.gender_pos }}</span>
      </span>
      <span v-if="!highlightPositions && result.cat && !race.team && hasColumn('CATEGORY')" class="ms-2">
          • <span class="grey--text">{{ getColumnHeader('CATEGORY') || $t('results.cols.category')}}</span> {{ result.cat }}:
          <span v-if="result.cat_pos" class="ml-0">#{{ result.cat_pos }}</span>
      </span>
      <span v-if="hasColumn('ACTIVITY_TYPES')" class="ms-2">
          • 
          <span v-for="(type, idx) in result.activity_types" :key="idx">
            <v-icon :color="textColor" small class="me-1">{{ $helpers.getActivityIcon(type) }}</v-icon>
            {{ $helpers.getActivityTypeLabel(type) }}
            <span v-if="idx != result.activity_types.length-1" class="me-1">, </span>
          </span>

      </span>
      <span v-if="result.last_activity && race && race.type !== 'EXTERNAL'" class="ms-2">
        • <span class="grey--text">{{$t('results.cols.last-activity')}}:</span> {{ result.last_activity | localDate('L', /*inUserTime: */ true) }}
      </span>
      <span v-if="result.group && !race.team" class="ms-2">
        • <span class="grey--text">{{$t('results.cols.group-team')}}:</span> {{ result.group }}
      </span>
      <span v-if="result.udf_1 && hasColumn('UDF_1')" class="ms-2">
        • <span class="grey--text">{{resultColumns.getColumnCaption(getColumn('UDF_1'))}}:</span> {{ result.udf_1 }}
      </span>
      <span v-if="result.udf_2 && hasColumn('UDF_2')" class="ms-2">
        • <span class="grey--text">{{resultColumns.getColumnCaption(getColumn('UDF_2'))}}:</span> {{ result.udf_2 }}
      </span>
      <span v-if="result.udf_3 && hasColumn('UDF_3')" class="ms-2">
        • <span class="grey--text">{{resultColumns.getColumnCaption(getColumn('UDF_3'))}}:</span> {{ result.udf_3 }}
      </span>
    </v-card-text>

<!--     <v-card-title class="display-1 px-3">
      Result details for {{ result.name }}
      <v-spacer/>
      <ShareButton :text="`Results at the ${event.name} - Check sodisp.com`" color="primary" :route="{name: 'raceResult', params: { id: event.id, raceId: race.id, resultId: result.id}}" />
    </v-card-title>
 -->    
    <v-card-text v-if="race && race.result_desc && !popupMode">
      <vue-markdown class="markdown" :html="false">{{race.result_desc }}</vue-markdown>
    </v-card-text>

    <v-card-text v-if="result && !result.started && !result.last_activity && !result.score_value">
      <div v-if="$helpers.isFutureEvent(event)">
        <p>{{ event.name }} opens in {{ $helpers.getStartDurationForEvent(event) | duration(true)}}. Please check back soon to see progress.</p>
      </div>
      <div v-else>
        <i>{{ result .name }} has not yet started. Please check back soon to see progress.</i>
        <p class="text-muted">Please note that it can take up to 10 minutes to update the leaderboard.</p>
      </div>
    </v-card-text>

    <!-- External Race Result Positions -->

    <v-card-text v-if="race && race.dist > 0 && result && showProgressBar" class="mt-0 pb-0">
      <div class="d-flex flex-row">
        <!-- <div class="shrink mt-4" style="white-space:nowrap;" >{{ formatChallengeValue(0) }}</div> -->
        <v-progress-linear striped class="ma-4" height="25" :color="event.color || 'green'" :value="Math.round(result.score_value / race.dist * 100)">
          <template v-slot="{ value }">
            <strong style="background:rgba(255,255,255,.6); padding: 2px 2px;">{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
<!--         <v-progress-linear class="ma-4 progress-bg" height="15" color="#097a00" background-opacity="0" :value="result.score_value / race.dist * 100">
        </v-progress-linear>
 -->        <div class="shrink mt-4" style="white-space:nowrap;">{{ formatChallengeValue(race.dist) }}</div>
      </div>
    </v-card-text>
    <v-container v-if="!showTrainingPlanProgress && result" class="mt-0 ml-0">
      <v-row>
        <v-col v-if="scoreColumn && result.score_value !== 0" cols="12" sm="4" md="4">
          <v-card class="white--text" :color="event.color ? $helpers.lightenDarkenColor(event.color, 0) : 'primary darken-1'">
            <v-card-text class="white--text pb-0">{{ resultColumns.getColumnCaption(scoreColumn) }}</v-card-text>
            <v-card-title class="subtitle pt-0">{{ $helpers.getScoreTooltipForDisplay($options, race, result, event) }}</v-card-title>
          </v-card>
        </v-col>
        <v-col v-if="secondaryScoreColumn" cols="12" sm="4" md="4">
          <v-card class="white--text" :color="event.color ? $helpers.lightenDarkenColor(event.color, -10) : 'primary darken-2'">
            <v-card-text class="white--text pb-0">{{ resultColumns.getColumnCaption(secondaryScoreColumn) }}</v-card-text>
            <v-card-title class="subtitle pt-0">{{ $helpers.getSecondaryValueForDisplay($options, race, result.secondary_value, event.unit) }}</v-card-title>
          </v-card>
        </v-col>
        <v-col v-if="showProgressBar && race.dist > 0" cols="12" sm="4" md="4">
          <v-card class="white--text" :color="event.color ? $helpers.lightenDarkenColor(event.color, -20) : 'primary darken-3'">
            <v-card-text class="white--text pb-0">{{$t('results.details.percentage-completed')}}</v-card-text>
            <v-card-title class="subtitle pt-0">{{ Math.round((result.score_value / race.dist) * 100) }}%</v-card-title>
          </v-card>
        </v-col>
        <v-col v-if="showProgressBar && result.score_value_day" cols="12" sm="4" md="4">
          <v-card class="white--text" :color="event.color ? $helpers.lightenDarkenColor(event.color, -30) : 'primary darken-4'">
            <v-card-text class="white--text pb-0">{{$t('results.details.avg-day')}}</v-card-text>
            <v-card-title class="subtitle pt-0">{{ $helpers.getGoalDistanceForDisplay($options, race, result.score_value_day, event) }}</v-card-title>
          </v-card>
        </v-col>
        <v-col v-if="!showProgressBar && showPace && result && result.pace" cols="12" sm="4" md="4">
          <v-card class="white--text" :color="event.color ? $helpers.lightenDarkenColor(event.color, -20) : 'primary darken-3'">
            <v-card-text class="white--text pb-0">{{$t('results.cols.pace')}}</v-card-text>
            <v-card-title class="subtitle pt-0">{{ result.pace | pace(event.unit, race.activity_types) }}</v-card-title>
          </v-card>
        </v-col>
        <v-col v-if="!showProgressBar && showSpeed && result && result.speed" cols="12" sm="4" md="4">
          <v-card class="white--text" :color="event.color ? $helpers.lightenDarkenColor(event.color, -20) : 'primary darken-3'">
            <v-card-text class="white--text pb-0">{{$t('results.cols.speed')}}</v-card-text>
            <v-card-title class="subtitle pt-0">{{ result.speed | speed(event.unit, race.activity_types) }}</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div v-if="race && result" class="mt-4">
      <v-card-text v-if="race.result_completed_desc" class="pt-0">
        <vue-markdown class="markdown" :html="true">{{ race.result_completed_desc | replace(result) }}</vue-markdown>
      </v-card-text>
      <v-alert v-if="result.completion_date && race.type !== 'EXTERNAL'" type="success" class="my-0" prominent tile>{{$t('results.details.completed-at')}} {{ result.completion_date | localDate('ddd L', /*ignoreTimeZone: */ true) }}</v-alert>
      <v-alert v-if="result.award_pos" type="info" icon="fa-trophy" class="my-0 ablack--text" prominent tile :color="$helpers.getAwardPositionColor(result.award_pos)">
        <h3>{{$t('results.details.award')}}</h3>
        <span v-if="result.award_cat">{{$t('results.details.award-in-category', { pos: result.award_pos, category: result.award_cat })}}</span>
        <span v-else>{{$t('results.details.award-message', { pos: result.award_pos})}}</span>
      </v-alert>
      <v-card-text v-if="result.certificate_img" class="pt-0 mt-4">
        <v-sheet elevation="1" class="pa-1 d-inline-block"><img :src="result.certificate_img" style="max-width:100%; max-height:300px;" /></v-sheet>
      </v-card-text>
      <v-card-text v-if="result.certificate_img" class="pt-0">
        <v-btn outlined :color="event.color || 'primary'" :href="result.certificate_img" target="_blank" >
          <v-icon class="mr-2">fa-trophy</v-icon> Download certificate
        </v-btn>
        <ShareButton v-if="result.certificate_img" :text="`Finisher Certificate at the ${event.name} - Check sodisp.com`" :color="event.color || 'primary'" :url="result.certificate_img" />
      </v-card-text>
    </div>

    <v-container v-if="result && highlightPositions" class="mt-0 ml-0 grey lighten-4">
      <v-row>
        <v-col v-if="result.pos" cols="12" sm="4" md="4">
          <v-card class="card--outlined" :color="event.color ? $helpers.lightenDarkenColor(event.color, 0) : 'primary darken-1'">
            <v-card-text class="Awhite--text pb-0"><div class="font-xs">{{$t('results.cols.position')}}</div>{{ $t('results.cols.overall') }}</v-card-text>
            <v-card-title class="subtitle pt-0">#{{ result.pos }}</v-card-title>
            <v-card-text v-if="meta && meta.total_count" class="Awhite--text mt-n4">(out of {{ meta.total_count | int }})</v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="result.gender && result.gender_pos && !race.team" cols="12" sm="4" md="4">
          <v-card class="card--outlined" :color="event.color ? $helpers.lightenDarkenColor(event.color, -10) : 'primary darken-2'">
            <v-card-text class="Awhite--text pb-0"><div class="font-xs">{{$t('results.cols.gender')}}</div> {{ result.gender }}</v-card-text>
            <v-card-title class="subtitle pt-0">#{{ result.gender_pos }}</v-card-title>
            <v-card-text v-if="resultGenderCount" class="Awhite--text mt-n4">(out of {{ resultGenderCount | int }})</v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="result.cat && result.cat_pos && !race.team" cols="12" sm="4" md="4">
          <v-card class="card--outlined" :color="event.color ? $helpers.lightenDarkenColor(event.color, -20) : 'primary darken-3'">
            <v-card-text class="Awhite--text pb-0"><div class="font-xs">{{getColumnHeader('CATEGORY') || $t('results.cols.category')}}</div> {{ result.cat }}</v-card-text>
            <v-card-title class="subtitle pt-0">#{{ result.cat_pos }}</v-card-title>
            <v-card-text v-if="resultCatCount" class="Awhite--text mt-n4">(out of {{ resultCatCount | int }})</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div v-if="map && race && result && race.route" class="mt-0 mb-8">
      <h3 class="mx-4">{{$t('events.race.course-map-title')}}</h3>
      <RaceResultsMap ref="resultMap" :event="event" :race="race" :meta="meta" :showResultPopup="false" @mapLoaded="tryLoadMap" />
    </div>

    <div v-if="showBadgeDateTimeSlots" class="mb-4">
      <v-card-title class="subtitle px-3">
        {{ $t('results.details.target-breakdown') }}
      </v-card-title>
      <v-alert v-if="race.personal_goal" type="info" tile>{{$t('events.rules.personal-goal')}}</v-alert>

      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{ $t('results.details.target') }}</th>
              <th class="text-left">{{ $t('results.details.badge-result') }}</th>
              <th class="text-left">{{ $t('events.race.goal-distance') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, idx) in badges.filter(x => x.include_challenge)">
            <tr v-if="!race.personal_goal || (result.badges||[]).find(x => x.id == item.id)" :set="badgeValue = result && (result.badges||[]).find(x => x.id == item.id)" :key="idx" :class="getBadgeRowClass(item)">
              <td>
                <div>{{ item.name }}</div>
                <div v-if="race.badge_scoring == 'DAILY_TARGET' && !item.from && badgeValue && badgeValue.reward_date" class="text-muted">{{ badgeValue.reward_date | localDate('L', /*inUserTime:*/true)}}</div>
                <div v-if="race.badge_scoring == 'WEEKLY_TARGET'" class="text-muted">{{ item.from | localDate('L', /*inUserTime:*/true)}} - {{ item.till | localDate('L', /*inUserTime:*/true)}}</div>
                <div v-if="race.badge_scoring == 'LAST_MAN_STANDING' && DateTime.fromISO(item.till).diff(DateTime.fromISO(item.from), ['seconds']).toObject().seconds == (60 * 60 * 24) - 1" class="text-muted">{{ item.from | localDate('L', /*inUserTime:*/true)}}</div>
                <div v-else-if="race.badge_scoring == 'LAST_MAN_STANDING' && DateTime.fromISO(item.till).diff(DateTime.fromISO(item.from), ['seconds']).toObject().seconds == (7 * 60 * 60 * 24) - 1" class="text-muted">{{ item.from | localDate('L', /*inUserTime:*/true)}} - {{ item.till | localDate('L', /*inUserTime:*/true)}}</div>
                <div v-else-if="race.badge_scoring == 'LAST_MAN_STANDING'" class="text-muted">{{ item.from | localDate('L LTS Z', /*inUserTime:*/true)}} - {{ item.from | localDate('L LTS Z', /*inUserTime:*/true)}}</div>
              </td>
              <td >
                <span v-if="badgeValue && badgeValue.format == 'PERCENTAGE'">
                  <v-progress-circular :value="badgeValue.pct * 100" :color="badgeValue.pct < 1 ? 'black' : event.color || 'primary'" size="40" rotate="-90" class="mr-2">
                    <span v-if="badgeValue.pct < 1 && event.hide_result_details" style="font-size:70%;">x</span>
                    <span v-else-if="badgeValue.pct < 1" style="font-size:70%;">{{ Math.round(badgeValue.pct * 100) }}%</span>
                    <span v-else-if="badgeValue.pct < 2 || event.hide_result_details" style="font-size:100%;">✔</span>
                    <span v-else style="font-size:100%;">{{Math.floor(badgeValue.pct)}}x</span>
                  </v-progress-circular>
                </span>
                <span v-if="!event.hide_result_details">
                  {{badgeValue ? (badgeValue.value ? $helpers.getBadgeValueForDisplay($options, event, race, item.goal_format, badgeValue.value) : '✔') : ''}}
                </span>
              </td>
              <td >{{ $helpers.getBadgeValueForDisplay($options, event, race, item.goal_format, (badgeValue && badgeValue.goal) || item.goal) }}</td>
            </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <div v-if="meta && meta.histogram && event && result" class="mb-4">
      <v-card-title class="subtitle px-3">
        {{ $t('results.details.finish-time-in-field') }}
      </v-card-title>
      <RaceHistogram :data="meta.histogram" :highlightColor="event.color" :highlightMinute="Math.floor(-1*result.score_value / 60)" />
    </div>
    <div v-if="result && result.score_by_date && event" class="mb-4">
      <v-card-title class="subtitle px-3">
        {{ scoreByDayLabel }}
      </v-card-title>
      <RaceHistogramByDate :data="result.score_by_date" :label="scoreByDayLabel" :defaultColor="event.color || $vuetify.theme.defaults.light.primary"  :accentColor="$helpers.getColorVariant(event.color || $vuetify.theme.defaults.light.primary, 100)" />
    </div>

    <div v-if="splits && splits.length > 0 && result" class="mb-4">
      <v-card-title class="subtitle px-3">
        {{ $t('results.details.split-breakdown') }}
      </v-card-title>

      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{ $t('results.details.split') }}</th>
              <th class="text-left">{{ $t('results.details.split-time') }}</th>
              <th class="text-left">{{ $t('results.details.time-from-start') }}</th>
              <th class="text-left">{{ $t('results.details.speed-or-pace') }}</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in splits" :key="idx">
              <td>
                <div  class="text-bold">{{ item.name }}</div>
                <div v-if="item.goal" class="text-muted">{{ item.goal | distance(event.unit, /*ignore_unit*/false, [item.activity_type]) }} </div>
              </td>
              <td :set="badgeValue = (result.badges||[]).find(x => x.id == item.id)">
                <span v-if="badgeValue && badgeValue.format === 'VALUE'">
                  {{ badgeValue.value }}
                </span>
                <span v-else-if="badgeValue">
                  {{ $helpers.getBadgeValueForDisplay($options, event, race, 'DURATION', badgeValue.value) }}
                </span>
              </td>
              <td :set="badgeValue = (result.badges||[]).find(x => x.id == item.id)">
                <span v-if="false && badgeValue && badgeValue.reward_date && result.last_activity && result.time && idx == splits.length -1">
                  <!-- note: disabled for now as the last split might be on a different distance than the race distance -->
                  <!-- use result time for last split to ensure it's always consistent (rounding issues) -->
                  {{ result.time | time }} 
                </span>
                <span v-else-if="badgeValue && badgeValue.reward_date && result.last_activity">
                  {{ DateTime.fromISO(badgeValue.reward_date).diff(DateTime.fromISO(result.last_activity), ['seconds']).toObject().seconds | time }} 
                </span>
              </td>
              <td :set="badgeValue = (result.badges||[]).find(x => x.id == item.id)">
                <span v-if="badgeValue && item.goal">
                  <span v-if="item.unit === 'FIXED'">
                    {{ $helpers.getPaceOrSpeed($options, item.goal, DateTime.fromISO(badgeValue.reward_date).diff(DateTime.fromISO(result.last_activity), ['seconds']).toObject().seconds, event.unit, item.activity_type) }} 
                  </span>
                  <span v-else>
                    {{ $helpers.getPaceOrSpeed($options, item.goal, badgeValue.value, event.unit, item.activity_type) }} 
                  </span>
                  <!-- {{ $helpers.getPaceOrSpeed($options, item.goal, badgeValue.value, event.unit, item.activity_type) }}  -->
                </span>
              </td>
              <td v-if="meta" :set="badgeStats = (meta.badges||[]).find(x => x.badge_id == item.id)">
                <v-sparkline
                  v-if="false && badgeStats && badgeStats.histogram"
                  :fill="true"
                  :smooth="false"
                  :line-width="10"
                  padding="1"
                  :value="Object.values(badgeStats.histogram)"
                  type="bars"
                  />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <div v-if="result && result.badges && resultBadgesForDisplay.length > 0 && !showTrainingPlanProgress && (!splits || splits.length == 0)" class="mt-0 mx-4">
      <h3>{{$t('results.details.earned-badges')}}</h3>
      <v-row>
        <v-col
          v-for="(item, idx) in resultBadgesForDisplay"
          :key="idx"
          cols="6"
          sm="4"
          md="2"
          lg="2"
          class="text-center pa-4"
        >
          <v-sheet :elevation="0" color="white">
            <strong class="py-2 d-block">{{ item.name }}</strong>
            <img :src="item.img" style="max-width: 100%;"/>
            <div class="py-1 text-muted">{{ item.reward_date | localDate('L', /*ignoreTimeZone: */ true)}}</div>
            <v-chip v-if="item.value" outlined :color="event.color || 'primary'" class="py-1">{{$t('results.details.badge-result')}}: {{ $helpers.getBadgeValueForDisplay($options, event, race, item.format, item.value) }}</v-chip>
            <v-chip v-if="item.bonus" outlined :color="event.color || 'accent'" class="py-1">{{$t('results.details.badge-bonus-result')}}: +{{ $helpers.getGoalDistanceForDisplay($options, race, item.bonus, event ) }}</v-chip>
            <BadgeDetailsDialog :event="event" :race="race" :badgeId="item.id" :detailsType="item.details_type" color="grey darken-2"/>
          </v-sheet>
        </v-col>
      </v-row>
    </div>

    <div v-if="showTrainingPlanProgress">
      <v-card-title class="subtitle px-3">
        {{$t('results.details.training-plan-progress')}}
      </v-card-title>
      <TrainingPlanProgress :training-plan="trainingPlan" :result="result" :badges="badges" :meta="meta" :event="event" :race="race" />
    </div>

    <div v-if="showRelaySlots">
      <v-card-title class="subtitle px-3">
        {{$t('results.details.relay-slot-breakdown')}}
      </v-card-title>
      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-if="hasSlotSports" class="text-left">{{$t('profile.activities.sport')}}</th>
              <th class="text-left">{{$t('events.cols.slot')}}</th>
              <th class="text-left">{{$t('events.cols.participant')}}</th>
              <th v-if="race.team == 'RELAY_TIME_SLOTS'" class="text-left">{{$t('results.cols.distance')}}</th>
              <th v-if="race.team == 'RELAY_DISTANCE_SLOTS'" class="text-left">{{$t('results.cols.time')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in result.slots" :key="idx" :class="getResultRowClass(item)">
              <td v-if="hasSlotSports">
                <v-icon v-if="item.slot_type">{{$helpers.getActivityIcon(item.slot_type)}}</v-icon>
              </td>
              <td>{{ item.slot_name }}</td>
              <td>{{ item.participant }}</td>
              <td v-if="!item.value"></td>
              <td v-if="item.value && race.team == 'RELAY_TIME_SLOTS'">{{ item.value | distance(event.unit) }} </td>
              <td v-if="item.value && race.team == 'RELAY_DISTANCE_SLOTS'">{{ item.value | duration }} </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div v-if="showTiles">
      <v-card-title class="subtitle px-3">
        Tiles covered
      </v-card-title>
      <v-card-text>{{result.name}} has covered {{ (result.tiles||[]).length }} tiles. Click a tile to view details.</v-card-text>
      <TileMap ref="tileMap" :tileOptionCreator="getTileStyle" preventAutoLoad @selected="tileSelected"/>

      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Tile</th>
              <th class="text-left">Distance</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in sortedTileResults" :key="idx" :class="getResultRowClass(item)">
              <td>{{ item.name }}</td>
              <td>{{ item.val | distance(event.unit) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

    </div>

    <div v-if="race && race.type !== 'EXTERNAL' && !showBadgeDateTimeSlots && !showRelaySlots && hasDetails && !showTrainingPlanProgress && (race.team || !showTiles) && !isBadgeRaceWithZeroPoints && !isTieredRaceWithCustomFormula">
      <v-card-title v-if="race.team" class="subtitle px-3">
        {{$t('results.details.breakdown-team-member')}}
      </v-card-title>
      <v-card-title v-if="!race.team" class="subtitle px-3">
        {{$t('results.details.breakdown-activity')}}
      </v-card-title>
      <v-card-text v-if="result.score_value == 0 && race.team && result.data.length > 0">
        <v-alert type="info">{{$t('results.details.team-requirements-not-met')}}</v-alert>
      </v-card-text>
      <v-card-text v-if="race.team && race.team_scoring_aggr == 'AVG'">
        <v-alert type="info">{{$t('results.details.team-scoring-using-avg')}}</v-alert>
      </v-card-text>
      <v-skeleton-loader v-if="$store.getters.loading" type="table"></v-skeleton-loader>
      <v-simple-table fixed-header >
        <template v-slot:default>
          <thead>
            <tr>
              <th v-if="!race.team" class="text-left">{{$t('profile.activities.sport')}}</th>
              <th v-if="!race.team" class="text-left">{{$t('profile.activities.source')}}</th>
              <th v-if="!race.team" class="text-left">{{$t('results.cols.date')}} *</th>
              <th v-if="race.team" class="text-left">{{$t('events.cols.participant')}}</th>
              <th v-if="race.team && race.team_gender_results" class="text-left">{{$t('results.cols.gender')}}</th>
              <th v-if="race.team && filter && filter.category" class="text-left">{{$t('results.cols.category')}}</th>
              <th v-if="race.team && race.scoring == 'RESULT'" class="text-left">{{$t('results.cols.date')}} *</th>
              <th v-if="race.team && race.scoring != 'RESULT'" class="text-left">{{$t('results.cols.last-activity')}} *</th>
              <th v-if="race.team && race.scoring == 'BADGES'" class="text-left font-weight-bold">{{$t('results.cols.points')}}</th>
              <th v-if="race.scoring == 'RESULT' || race.scoring == 'TRACK'" class="text-left font-weight-bold">{{$t('results.cols.corrected-time')}}</th>
              <th v-if="race.scoring == 'CALORIES'" class="text-left font-weight-bold">{{$t('events.cols.calories')}}</th>
              <th v-if="race.scoring == 'DISTANCE'" class="text-left font-weight-bold">{{$t('events.cols.distance')}}</th>
              <th v-if="race.scoring == 'TIME'" class="text-left font-weight-bold">{{$t('events.cols.total-time')}}</th>
              <th v-if="race.scoring == 'Tiers'" class="text-left font-weight-bold">{{$t('results.cols.score')}}</th>
              <th v-if="race.scoring == 'CUSTOM'" class="text-left font-weight-bold">
                {{race.custom||$t('profile.activities.points')}}
                <TooltipDialog v-if="race.scoring_desc" title="Scoring for this race" :text="race.scoring_desc" />
              </th>
              <th v-if="showStairs" class="text-left font-weight-bold">{{$t('results.cols.stairs')}}</th>
              <th v-if="showDuration" class="text-left">{{$t('results.cols.time')}}</th>
              <th v-if="showDistance" class="text-left">{{$t('events.cols.distance')}}</th>
              <th v-if="showElevation" class="text-left">{{$t('results.cols.elevation')}}</th>
              <th v-if="showSteps" class="text-left">{{ event.result_format === 'POINTS' ? $t('results.cols.points') : $t('results.cols.steps')}}</th>
              <th v-if="showCustom && !showStairs" class="text-left">{{ race.custom }}</th>
              <th v-if="showPace" class="text-left">{{$t('results.cols.pace')}}</th>
              <th v-if="showSpeed" class="text-left">Strokes per min</th>
              <th class="text-left px-0">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in result.data" :key="idx" :class="getResultRowClass(item)">
              <td v-if="!race.team" class="pr-0" style="width:30px;"><v-icon>{{$helpers.getActivityIcon(item.type)}}</v-icon></td>
              <td v-if="!race.team" class="pr-0" style="width:30px;"><v-icon :title="item.prov">{{$helpers.getProviderIcon(item.prov)}}</v-icon></td>
              <td v-if="race.team">{{ item.name }}</td>
              <td v-if="race.team && race.team_gender_results" :title="$helpers.getGenderForDisplay(item.gender, /*full:*/true)">{{$helpers.getGenderForDisplay(item.gender)}}</td>
              <td v-if="race.team && filter && filter.category">{{item.cat}}</td>
              <td>{{ item.start | localDate('L', /*ignoreTimeZone: */ true) }}</td>
              <td class="font-weight-bold" v-if="race.team && race.scoring == 'BADGES'">{{ item.v }} </td>
              <td class="font-weight-bold" v-if="race.scoring == 'RESULT' || race.scoring == 'TRACK'">{{ -1*item.v | duration }} </td>
              <td class="font-weight-bold" v-if="race.scoring == 'DISTANCE'">{{ item.v | distance(event.unit, false, race.activity_types) }} </td>
              <td class="font-weight-bold" v-if="race.scoring == 'CALORIES'">{{ item.v }} kcal </td>
              <td class="font-weight-bold" v-if="race.scoring == 'TIME'">{{ item.v | duration }} </td>
              <td class="font-weight-bold" v-if="race.scoring == 'STAIRS'">{{ item.v }} </td>
              <td class="font-weight-bold" v-if="race.scoring == 'CUSTOM'">{{ item.v }} </td>
              <td class="font-weight-bold" v-if="race.scoring == 'TIERS'">{{ $helpers.getSecondaryValueForDisplay($options, race, item.v, event.unit) }} </td>
              <td class="font-weight-bold" v-if="race.scoring != 'STAIRS' && showStairs">{{ item.custom }} </td>
              <td v-if="showDuration">{{ item.t | duration }} </td>
              <td v-if="showDistance">{{ item.d | distance(event.unit, false, race.activity_types) }}</td>
              <td v-if="showElevation">{{ item.e | elevation(event.unit) }}</td>
              <td v-if="showSteps">{{ item.steps | int }}</td>
              <td v-if="showCustom && !showStairs">{{ item.custom }}</td>
              <td v-if="showPace">{{ item.t / (item.d/1000) | pace(event.unit, race.activity_types && race.activity_types.length == 1 ? race.activity_types[0] : null) }} </td>
              <td v-if="showSpeed">{{ item.custom | speed(event.unit, race.activity_types && race.activity_types.length == 1 ? race.activity_types[0] : null) }} </td>
              <td v-if="adminMode" class="text-left px-0">
                <v-icon v-if="race.team && adminMode" small color="red" class="ml-2" title="Delete user from result" @click.stop="deleteProfileFromResult(item)">fa-trash</v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card-text v-if="race.type !== 'EXTERNAL'" class="text-muted">{{$t('results.details.local-time-message')}}</v-card-text>
    </div>
    <v-card-title v-if="result && result.photos && event.allow_photos" class="subtitle px-3" ref="photos">
      {{$t('results.details.photos-title')}}
    </v-card-title>
    <PhotoGallery v-if="result && result.photos && event.allow_photos" ref="photoGallery" :photos="photos" :detailsUrl="result.photos_url" @galleryOpen="$emit('photoGalleryOpen')" @galleryClose="$emit('photoGalleryClose')" />
    <v-card-title v-if="result && result.id && !isEmbeddedInTeams && allowComments" class="subtitle px-3" ref="comments">
      {{$t('results.details.comments-description')}}
    </v-card-title>
    <CommentBox v-if="result && result.id && !isEmbeddedInTeams && allowComments" :comments="comments" :selfId="result.id" @post="postComment" @delete="deleteComment" publicVisible/>
  </div>
</template>

<script>
import {DateTime} from "luxon";
import assetsService from "@/services/assetsService";
import profileService from "@/services/profileService";
import eventService from "@/services/eventService";
import eventManagerService from "@/services/eventManagerService";
import ActivityScoreChip from "@/components/ActivityScoreChip";
import ActivityBonusScoreChip from "@/components/ActivityBonusScoreChip";
import ActivityPenaltyScoreChip from "@/components/ActivityPenaltyScoreChip";
import TrainingPlanProgress from "@/components/TrainingPlanProgress";
import RaceHistogram from "@/components/RaceHistogram";
import RaceHistogramByDate from "@/components/RaceHistogramByDate";
import TileMap from "@/components/TileMap.vue";
import BadgeDetailsDialog from "@/components/BadgeDetailsDialog";
import RaceResultsMap from "@/components/RaceResultsMap.vue";
import ShareButton from "@/components/ShareButton";
import CommentBox from "@/components/CommentBox";
import PhotoGallery from "@/components/PhotoGallery";
import TooltipDialog from "@/components/TooltipDialog";
import VueMarkdown from '@/components/VueMarkdown.vue'
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import resultColumns from '@/util/resultColumns.js'

export default {
  name: "RaceResultDetails",
  components: {
    ActivityScoreChip,
    ActivityBonusScoreChip,
    ActivityPenaltyScoreChip,
    RaceHistogram,
    RaceHistogramByDate,
    TrainingPlanProgress,
    TileMap,
    BadgeDetailsDialog,
    RaceResultsMap,
    TooltipDialog,
    VueMarkdown,
    ShareButton,
    CommentBox,
    PhotoGallery,
    TrainingPlanProgress,
  },
  props: {
      event: Object,
      race: Object,
      badges: Array,
      meta: Object,
      result: Object,
      filter: Object,
      popupMode: Boolean,
      adminMode: Boolean,
      map: Boolean,
      focusSection: String, // section to focus/scroll to on load
  },
  data() {
    return {
      DateTime: DateTime,
      details: null,
      columns: [],
      liked: false,
      comments: null,
      photos: null,
      trainingPlan: null,
      resultColumns: resultColumns,
      showTileRankingsEventName: 'tile-show-rankings',
      claimVisible: true,
    };
  },
  async mounted() {
    await this.tryLoadComments();
    await this.tryLoadPhotos();
    await this.tryLoadTrainingPlan();
    this.loadCustomColumns();
  },
  methods: {
    getResultRowClass(item) {
      if (item.included === true) {
        return 'font-weight-bold'
      }
      if (item.included === false) {
        return 'grey--text'
      }
      return null;
    },
    getBadgeRowClass(badge) {
      if (badge.from && badge.till && this.$helpers.isTimeInBetween(badge.from, badge.till)) {
        return 'font-weight-bold'
      }
      if (badge.till && this.$helpers.isFutureTimestamp(badge.till)) {
        return 'grey--text'
      }
      return null;
    },
    async claimResult() {
      this.claimVisible = !this.claimVisible;
    },
    async like() {
      const response = (await eventService.likeResult(this.event.id, this.race.id, this.result.id, this.filter)).data;
      if (response.status == 'OK') {
        this.liked = true;
        this.$analytics.event('liked', { 'event_category': 'result', 'event_label': `${this.event.id}.${this.race.id}.${this.result.id}` });
      }
      this.$helpers.toastResponse(this, response, 'Thanks! Your 👍 has been delivered!');
    },
    async postComment(data) {
      //console.log('Adding comment', message);
      const response = (await eventService.commentResult(this.event.id, this.race.id, this.result.id, this.filter, data.comment)).data;
      if (response.status == 'OK') {
        this.$analytics.event('commented', { 'event_category': 'result', 'event_label': `${this.event.id}.${this.race.id}.${this.result.id}` });
        this.comments = (await eventService.getRaceResultComments(this.event.id, this.race.id, this.result.id, this.filter)).data.data;
      }
      this.$helpers.toastResponse(this, response, 'Thanks! Your 💬 has been delivered!');
      data.done();
    },
    async deleteComment(commentId) {
      const response = (await eventService.deleteResultComment(this.event.id, this.race.id, this.result.id, this.filter, commentId)).data;
      if (response.status == 'OK') {
        this.comments = (await eventService.getRaceResultComments(this.event.id, this.race.id, this.result.id, this.filter)).data.data;
      }
      this.$helpers.toastResponse(this, response, 'Your comment has been deleted.');
    },
    getTileStyle(tile) {
      return { fillColor: 'white', fillOpacity: .2, fill: true, weight: 3, color: 'white' } ;
    },
    async tileSelected(tile, polygon) {
      console.log('TILE selected in results', tile, polygon);
      const content = `<h3 class="mb-2">${tile.name || 'Tile'}</h3><p>${this.$helpers.getSecondaryValueForDisplay(this.$options, this.race, tile.val, this.event.unit)}</p><p class="my-0"><a class="v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default primary--text" onclick="EventBus.$emit('${this.showTileRankingsEventName}', '${tile.id}');"><span class="v-btn__content">View Tile Rankings &raquo;</span></a></p>`
      var popup = window.L.popup()
        .setLatLng(polygon.getCenter())
        .setContent(content)
        .openOn(polygon);
      this.$refs.tileMap.map.openPopup(popup);

      //this.$refs.tileMap.highlightTile(tile);
      //var tileResults = (await eventService.getRaceTileResults(this.event.id, this.race.id, tile.id)).data.data;
      //await this.loadTileResults(tile);
    },
    async tryLoadTiles() {
      await this.$nextTick();
      if (this.result && this.$refs.tileMap) this.$refs.tileMap.loadTiles(this.result.tiles || []);
    },
    async tryLoadComments() {
      this.comments = null;
      if (this.result == null) {
        return;
      }
      //console.log('Loading comments', this.result.comments);
      if (this.result.comments > 0) {
        this.comments = (await eventService.getRaceResultComments(this.event.id, this.race.id, this.result.id, this.filter)).data.data;
      }
    },
    async tryLoadPhotos() {
      this.photos = null;
      if (this.result == null || !this.event.allow_photos) {
        return;
      }
      if (this.result.photos !== 0) {
        this.photos = (await eventService.getRaceResultPhotos(this.event.id, this.race.id, this.result.id)).data.data;
        await this.$nextTick();
        if (this.$refs.photoGallery) {
          console.log('Loading photo gallery', this.photos);
          this.$refs.photoGallery.loadGallery();
        }
        if (this.focusSection === 'photos') {
          this.$refs.photos.scrollIntoView();
        }
      }
    },
    async tryLoadMap() {
      console.log('tryLoadMap', this.result, this.$refs.resultMap);
      if (this.result == null || this.$refs.resultMap == null) {
        return;
      }
      console.log('loading result on map now');
      this.$refs.resultMap.loadResults([this.result], this.meta);
    },
    async tryLoadTrainingPlan() {
      this.trainingPlan = null;
      if (this.result == null) {
        return;
      }
      if (this.race.scoring === 'TRAINING_PLAN') {
        this.trainingPlan = (await assetsService.getTrainingPlan(this.race.id)).data;
      }
      if (this.badges == null) {
        this.badges = (await eventService.getRaceBadges(this.event.id, this.race.id)).data.data;
      }
      console.log('Loaded training plan', this.trainingPlan);
    },
    formatChallengeValue(val) {
      if (this.race.scoring == 'DISTANCE') {
        return this.$options.filters.distance(val, this.event.unit);
      }
      if (this.race.scoring == 'ELEVATION') {
        return this.$options.filters.elevation(val, this.event.unit);
      }
      if (this.race.scoring == 'TIME') {
        return this.$options.filters.duration(val, /*compact:*/true);
      }
      if (this.race.scoring == 'STEPS') {
        if (this.event && this.event.result_format === 'POINTS') {
          return this.$i18n.t('results.formatting.points', { value: this.$options.filters.int(val)});
        }

        return this.$options.filters.int(val) + ' steps';
      }
      if (this.race.scoring == 'STAIRS') {
        return `${val} stairs`;
      }
      if (this.race.scoring == 'CALORIES') {
        return `${val} kcal`;
      }
      if (this.race.scoring == 'TRAINING_PLAN') {
        return `${val} workouts`;
      }
      if (this.race.scoring == 'CUSTOM') {
        return `${val} ${this.race.custom || 'points'}`;
      }
      return val;
    },
    loadCustomColumns() {
      if (this.race) {
        this.columns = resultColumns.mergeAndFilterResultColumns(this.event, this.race, this.meta, this.race.cols);
      }
    },
    hasColumn(type) {
      if (this.columns) {
        var col = this.columns.find(x => x.type === type);
        //console.log('hasColumn config', type, col && col.visible, col);
        return col && col.visible;
      }
      //console.log('hasColumn WITHOUT config', type);
      return false;
    },
    getColumn(type) {
      return this.columns == null ? null : this.columns.find(x => x.type == type);
    },
    getColumnHeader(type) {
      //console.log('getColumnHeader', type, this.hasColumn(type) ? this.getColumn(type).caption : null);
      return this.hasColumn(type) ? this.getColumn(type).caption : null;
    },
    async requestFriend() {
      if (confirm(this.$t('profile.friends.request-as-friend-msg', {user: this.result.name }))){
        const response = (await profileService.requestFriend(this.result.id)).data;
        this.$helpers.toastResponse(this, response, this.$t('profile.friends.request-confirmation'));
      }
    },
    async deleteProfileFromResult(item) {
      if (confirm(`Are you sure you want to delete the participant ${item.name} from the results?`)){
        var response = (await eventManagerService.deleteProfileFromTeamResultByName(this.event.id, this.race.id, this.result.id, item.name, item.v)).data;
        this.$helpers.toastResponse(this, response, 'The profile is deleted. It can take up to 10-15 minutes to show everywhere.');
      }
    },

  },
  computed: {
    showProgressBar: function() {
      if (!this.race) return false;
      if (!this.scoreColumn) return false; // hide all derived info as well

      return this.race.scoring == 'DISTANCE'
          || this.race.scoring == 'ELEVATION'
          || this.race.scoring == 'STAIRS'
          || this.race.scoring == 'BADGES'
          || this.race.scoring == 'STEPS'
          || this.race.scoring == 'TRAINING_PLAN'
          || this.race.scoring == 'TIME'
          || (this.race.scoring == 'CUSTOM' && this.result && this.result.progress)
          || this.race.scoring == 'CALORIES';
    },
    hideDailyTarget: function() {
      return this.event && this.race && this.event.hide_result_details && this.race.personal_goal;
    },
    showBadgeDateTimeSlots: function() {
      return this.badges && this.race && this.race.badge_scoring && ['WEEKLY_TARGET', 'DAILY_TARGET', 'LAST_MAN_STANDING'].some(x => x == this.race.badge_scoring);
    },
    showBadgeDateSlots: function() {
      return this.showBadgeDateTimeSlots && this.race.badge_scoring && ['WEEKLY_TARGET', 'DAILY_TARGET'].some(x => x == this.race.badge_scoring);
    },
    showBadgeTimeSlots: function() {
      return this.showBadgeDateTimeSlots && !this.showBadgeDateSlots;
    },
    sortedTileResults: function() {
      if (!this.result || !this.result.tiles) return [];
      return this.result.tiles.sort( (a,b) => b.val - a.val );
    },
    splits() {
      console.log('/// splits this.badges', this.badges, this.meta);
      return this.badges && this.badges.filter(x => x.metric === 'SPLIT');
    },
    badgesForDisplay() {
      return this.badges && this.badges.filter(x => x.metric !== 'SPLIT' && x.metric !== 'AGGREGATION');
    },
    hasDetails() {
      return this.result && this.result.data && this.result.data.length > 0;
    },
    showStairs: function() {
      return this.race && (this.race.scoring == 'STAIRS' || this.race.type == 'STAIR_CLIMBING');
    },
    highlightPositions: function() {
      return (this.race && this.race.type === 'EXTERNAL') || (this.result && this.result.official);
    },
    resultGenderCount: function() {
      return this.meta && this.result && this.result.gender && this.meta.count_by_gender && this.meta.count_by_gender[this.result.gender];
    },
    resultCatCount: function() {
      return this.meta && this.result && this.result.cat && this.meta.count_by_category && this.meta.count_by_category[this.result.cat];
    },
    showDuration: function() {
      if (!this.race) return false;
      if ((this.race.secondary_scoring == 'DURATION' || this.race.secondary_scoring == 'DURATION_FASTEST') && !this.showSteps) return true;

      return this.race.scoring != 'TIME' && !(this.race.team && this.race.scoring == 'BADGES')  && this.hasColumn('TIME');
    },
    showDistance: function() {
      if (!this.race) return false;
      return !(this.race.team && this.race.scoring == 'BADGES') && this.race.scoring != 'DISTANCE' && this.race.scoring != 'STAIRS' && this.race.type != 'STAIR_CLIMBING' && this.hasColumn('DISTANCE');
    },
    isRowing: function() {
      if (!this.race) return false;
      return this.race.activity_types && this.race.activity_types.length == 1 && this.race.activity_types[0] === 'ROWING';
    },
    showElevation: function() {
      if (!this.race) return false;
      return this.race.scoring == 'ELEVATION' || (this.showDistance && !this.isRowing && this.hasColumn('ELEVATION'));
    },
    showSteps: function() {
      if (!this.race) return false;
      return this.race.scoring == 'STEPS' || this.hasColumn('STEPS');
    },
    showCustom: function() { return this.hasColumn('CUSTOM'); },
    showPace: function() { return this.hasColumn('PACE'); },
    showSpeed: function() { return this.hasColumn('SPEED'); },
    
    showTrainingPlanProgress() {
      return this.race && this.race.scoring === 'TRAINING_PLAN';
    },
    resultBadgesForDisplay() {
      return this.result && this.result.badges && this.result.badges.filter(x => !(x.partial && x.format == 'PERCENTAGE'));
    },
    isBadgeRaceWithZeroPoints() {
      // this might be a calculated badge ranking where the result is stored in the secondary score only
      return this.race && this.race.scoring === 'BADGES' && this.result && this.result.score_value === 0;
    },
    isTieredRaceWithCustomFormula() {
      // this might be a calculated badge ranking where the result is stored in the secondary score only
      return this.race && this.race.scoring === 'TIERS' && this.result.data && !this.result.data.some(x => x.v);
    },
    showTiles() {
      return this.race && this.race.scoring === 'TILES';
    },
    showRelaySlots() {
      return this.race && (this.race.team == 'RELAY_TIME_SLOTS' || this.race.team == 'RELAY_DISTANCE_SLOTS') && this.result.slots;
    },
    hasSlotSports() {
      return this.showRelaySlots && this.result.slots.some(x => x.slot_type);
    },
    hasExternalPhotos() {
      return this.race && this.race.type === 'EXTERNAL' // external race
          && this.result && this.result.photos_url     // external photos
          && this.photos && this.photos.length;         // photos have been loaded
    },
    scoreColumn() {
      return this.columns == null ? null : this.columns.find(x => x.type == 'SCORE');
    },
    secondaryScoreColumn() {
      return this.columns == null ? null : this.columns.find(x => x.type == 'SECONDARY_SCORE');
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInTeams() {
      return this.$store.state.context === 'microsoft-teams';
    },
    allowLike() {
      return this.event && (!this.event.allowed_result_interactions || this.event.allowed_result_interactions.includes('LIKE'));
    },
    allowComments() {
      return this.event && (!this.event.allowed_result_interactions || this.event.allowed_result_interactions.includes('COMMENT'));
    },
    allowShare() {
      return this.event && (!this.event.allowed_result_interactions || this.event.allowed_result_interactions.includes('SHARE'));
    },
    allowFriend() {
      return false;//friends are not yet publicly visible/released
      return this.race && !this.race.team && this.event && this.user && (!this.event.allowed_result_interactions || this.event.allowed_result_interactions.includes('FRIEND'));
    },
    useHighContrast() {
      return this.$store.state.theme === 'highcontrast' || this.$route.query.theme === 'highcontrast';
    },
    isEmbeddedInApp() {
      if (this.isEmbeddedInTeams){
        return false;
      }
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },    
    scoreByDayLabel() {
      if (this.race && this.race.scoring == 'STEPS') {
        return this.event.result_format === 'POINTS' ? this.$t('results.details.score-by-day-points') : this.$t('results.details.score-by-day-steps');
      }
      else if (this.race && this.race.scoring == 'DISTANCE') {
        return this.$t('results.details.score-by-day-distance');
      }
      return this.$t('results.details.score-by-day');
    },
    ...mapGetters({
      user: "user"
    }),

  },
  watch: {
    async result(val) {
      await this.tryLoadComments();
      await this.tryLoadPhotos();
      await this.tryLoadTrainingPlan();
      await this.tryLoadTiles();
      //await this.tryLoadMap();
    },
    async race(val) {
      this.badges = null;
      this.loadCustomColumns();
    },
    async meta(val) {
      this.loadCustomColumns();
    },
  }

};
</script>
<style lang="scss">
  .v-application .container {max-width: 100% !important;}
  .progress-bg {
      background: linear-gradient(90deg, rgba(255,122,62,1) 0%, rgba(255,235,0,1) 35%, rgba(18,255,0,1) 100%); 

  }
  .profile--header {
    .profile--img { position: relative; top: 40px; left: 10px; margin-right:10px; }
  }
  .profile--actions.with-img { margin-left: 120px; margin-bottom: 25px;}
  .profile--actions {
  }
  .v-application .v-card.card--outlined {
    border-style: solid;
    border-width: 2px;
    background-color: white !important;
  }
</style>

